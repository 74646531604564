<p-toast [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display:
  'flex', 'justify-content': 'center', 'align-items': 'center',
  'flex-direction': 'column'}}">
</p-toast>
<!-- <p-confirmDialog header="Confirmation" [style]="{width: '50vw'}" [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"> -->
<!-- </p-confirmDialog> -->
<p-confirmDialog #cd [style]="{width: '30vw'}" [breakpoints]="{'960px':
  '92vw'}">
    <ng-template pTemplate="header">
        <h5>Confirmation</h5>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="cd.close()"
            class="p-button-rounded p-button-secondary p-button-text"></button>
    </ng-template>
    <ng-template pTemplate="footer">
        <!-- <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button> -->
        <!-- <p-button type="button" pButton icon="pi pi-check" styleClass="p-button-text" label="Yes"
      (click)="cd.accept()"></p-button> -->
        <p-button (click)="cd.accept()" icon="pi pi-check" label="Yes" styleClass="p-button-text"></p-button>
    </ng-template>
</p-confirmDialog>
<app-dashboard-sidemenu *ngIf="!previewCheck"></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column" *ngIf="!previewCheck">

    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <app-navbar-style-one></app-navbar-style-one>

    <!-- ********************** Breadcrumb Area  ******************** -->

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>

    <!-- ********************** End Breadcrumb Area  ******************** -->

    <!-- ********************** Add Master Form Area  ******************** -->
    <div class="Master-view-box">
        <div class="theader">
            <!-- <ng-template pTemplate="caption"> -->
            <div class="d-flex search-bar">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="onSearch('dt1', $event.target.value)"
                        placeholder="Search keyword" [(ngModel)]="filterVal" />
                </span>
                <p-button type="button" class="ml-2" (click)="reset()" [(ngModel)]="filterVal" icon="bx
          bx-reset"></p-button>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">

                <!-- <ng-container *ngIf="localStorage.role == 'Auditor'"> -->

                <p-table *ngIf="!previewCheck" #dt1 [value]="users" sortField="award_category" sortMode="single"
                    dataKey="award_category" rowGroupMode="subheader" groupRowsBy="award_category" [rows]="10"
                    [showCurrentPageReport]="true" styleClass="p-datatable-sm
            p-datatable-customers" editMode="row" responsiveLayout="scroll" [globalFilterFields]="['company_name']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:20%">S No.</th>
                            <th style="width:20%">Company Name</th>
                            <th style="width:20%">Award Name</th>
                            <th style="width:20%">Status</th>
                            <th style="width:20%">Export</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="groupheader" let-customer let-rowIndex="rowIndex" let-expanded="expanded">
                        <tr>
                            <td colspan="4">
                                <button type="button" pButton pRipple [pRowToggler]="customer"
                                    class="p-button-text p-button-rounded p-button-plain mr-2"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                <span class="font-bold ml-2"
                                    style="padding: 8px;display:inline-block;">{{customer?.award_category}}</span>
                            </td>
                            <td colspan="1">
                                <!-- <button class="p-button" pTooltip="Excel" (click)="downloadXL(customer?.award_category)"><i class="pi pi-file-excel" style="margin-right: 5px;"></i>Excel</button> -->
                                <!-- <a [href]="filePath" download>download</a> -->
                            </td>
                        </tr>
                    </ng-template>
                    <!-- <ng-template pTemplate="groupfooter" let-customer>
              <tr class="p-rowgroup-footer">
                <td colspan="4" style="text-align: right">Total Customers</td>
                <td>{{calculateCustomerTotal(customer.representative.name)}}</td>
              </tr>
            </ng-template> -->
                    <ng-template pTemplate="rowexpansion" let-customer let-i="rowIndex">
                        <tr>
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{customer?.company_name}}
                            </td>
                            <td>
                                {{customer?.award_name}}
                            </td>
                            <td class="status">
                                <span class="pending" *ngIf="customer.status == ''">
                                    Pending
                                </span>
                                <span class="pending" *ngIf="customer.status == null">
                                    Pending
                                </span>
                                <span class="in-progress" *ngIf="customer.status == 'In Progress'">
                                    In Progress
                                </span>
                                <span class="approved" *ngIf="customer.status == 'Completed'">
                                    Completed
                                </span>
                            </td>
                            <td>
                                <button class="p-button" pTooltip="PDF" (click)="downloadPDF(customer)"><i
                                        class="pi pi-file-pdf" style="margin-right: 5px;"></i> Site Visit Doc.</button>
                                        <button class="p-button" pTooltip="PDF" (click)="downloadJuryEvaluation(customer)"><i
                                            class="pi pi-file-pdf" style="margin-right: 5px;"></i> Jury Evaluation</button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- </ng-container> -->
            </div>
        </div>
    </div>

    <app-copyrights></app-copyrights>
</div>

<ng-container *ngIf="!juryEvaluation">
<ng-container *ngIf="previewCheck">
    <!-- {{sections | json}} -->
    <div class="add-listings-box" *ngIf="matchingLabelsAndValues.length > 0">
        <div class="heading">
            <img src="assets/img/ucci-awards-logo.png" alt="">
            <h2 style="margin-bottom: 0;font-size: 25px;">Udaipur Chamber of Commerce & Industry</h2>
            <h2 style="margin-top: 0;font-size: 25px;">UCCI Excellence Awards 2025</h2>
            <h2 style="margin-bottom: 0;font-size: 25px;">Site Visit- {{award_name}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Company Name- {{company_name}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Contact Person- {{contactPersonName}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Contact Number- {{companyNumber}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Company Address- {{companyAddress}}</h2>
        </div>
            <h2 style="margin-top: 0;font-size: 15px;">Name of the Visitor- </h2>
            <h2 style="margin-top: 0;font-size: 15px;align-items:start;">Date of Visit- </h2>
        <div class="heading">    
            <div *ngIf="award_name !== 'Vedanta Hindustan Zinc CSR Award'" style="width: 100%; padding-left: 50px; padding-right: 31px; display: flex; justify-content: center; text-align: justify; ">{{companySummary}}</div>
            <!-- <h2>{{title}} {{nextYear}} / {{hindiTitle}} {{nextYear}}</h2> -->
            <!-- <p> Please complete the form below and provide details of your business and company.
                कृपया निम्नलिखित फार्म को पूरा भरें तथा अपने व्यवसाय एवं कम्पनी के विषय में जानकारी देवे:</p> -->
            </div>
        <div class="d-flex justify-content-between align-items-center position-sticky button_div"
            style="top: 0;background:white;padding:20px 0;">
            <p-button type="button" #back_btn class="test " (click)="previewCheck = false" icon="bx bx-arrow-back"
                label="" iconPos="left">
            </p-button>
            <button (click)="getPDF()" class="pdf_btn">Save as PDF</button>
        </div>
        <!-- <br> -->

        <!-- <table class="MsoTableGrid" cellspacing="0"
            style="width: 100%;border-collapse:collapse;border:none;mso-border-left-alt:0.5000ptsolidrgb(0,0,0);
mso-border-top-alt:0.5000ptsolidrgb(0,0,0);mso-border-right-alt:0.5000ptsolidrgb(0,0,0);mso-border-bottom-alt:0.5000ptsolidrgb(0,0,0);
mso-border-insideh:0.5000ptsolidrgb(0,0,0);mso-border-insidev:0.5000ptsolidrgb(0,0,0);mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;">
            <tbody>
                <tr>
                    <td width="49" valign="top" style="width:36.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">S. No</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="380" valign="top" style="width:285.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">Parameter</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="214" valign="top" style="width:161.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">Remarks</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                </tr>
                <tr style="height:90pt;">
                    <td width="49" valign="top" style="width:36.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">1</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="380" valign="top" style="width:285.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">Relevance and depth of the responses in
                                context
                                of the company’s scale and industry</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p>&nbsp;</o:p>
                            </span></p>
                    </td>
                    <td width="214" valign="top" style="width:161.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p>&nbsp;</o:p>
                            </span></p>
                    </td>
                </tr>
                <tr style="height:90pt;">
                    <td width="49" valign="top" style="width:36.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">2</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="380" valign="top" style="width:285.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">Awareness, understanding and sincerity
                                of
                                company’s vision within the key members of the company</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="214" valign="top" style="width:161.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p>&nbsp;</o:p>
                            </span></p>
                    </td>
                </tr>
                <tr style="height:90pt;">
                    <td width="49" valign="top" style="width:36.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">3</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="380" valign="top" style="width:285.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">Actual implementation and practice of
                                the
                                aspects as answered by the applicant company</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="214" valign="top" style="width:161.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p>&nbsp;</o:p>
                            </span></p>
                    </td>
                </tr>
                <tr style="height:90pt;">
                    <td width="49" valign="top" style="width:36.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">4</span><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="380" valign="top" style="width:285.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">Overall attitude about excellence,
                                professionalism and growth</span><span style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p></o:p>
                            </span></p>
                    </td>
                    <td width="214" valign="top" style="width:161.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);">
                        <p class="MsoNormal" style="margin-bottom:0.0000pt;"><span
                                style="font-family:Calibri;font-size:11.0000pt;">
                                <o:p>&nbsp;</o:p>
                            </span></p>
                    </td>
                </tr>
            </tbody>
        </table> -->
        <!-- <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br><br> -->

        <div class="preview">
            <!-- <ng-container *ngFor="let section of sections"> -->
            <!-- <div class="row m-0">
                    <div class="col-md-6" style="width: 50%;">
                      <h2 class="text-center my-3 pb-3" style="border-bottom: 1px dotted lightgray; font-size: 14px; padding-bottom: 5rem !important; font-size: 19px;">
                        Parameter
                      </h2>
                      <ng-container *ngFor="let control of matchingLabelsAndValues; let i = index">
                        <div class="d-flex justify-content-between" style="align-items: normal;">
                          <div *ngIf="!control.col" style="font-weight: bold; font-size: 20px;">Q{{i+1}}</div>
                          <div class="group" style="width: 95%;">
                            <ng-container *ngIf="!control.col">
                              <label for="" class="label">{{control.label}}</label>
                              <div class="value">
                                <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                <span>{{control.value != '' ? control.value : '-'}}</span>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  
                    <div class="col-md-3" style="border-left: 1px solid lightgray; width: 15%;">
                      <h2 class="text-center my-3 pb-3" style="border-bottom: 1px dotted lightgray; font-size: 14px; padding-bottom: 2rem !important;">Depth of articulation realized (Rate out of 10)</h2>
                    </div>
                  
                    <div class="col-md-3" style="border-left: 1px solid lightgray; width: 15%;">
                      <h2 class="text-center my-3 pb-3" style="border-bottom: 1px dotted lightgray; font-size: 14px; padding-bottom: 3rem !important;">Actual awareness, implementation, and impact (Rate out of 10)</h2>
                    </div>
                  
                    <div class="col-md-4" style="border-left: 1px solid lightgray; width: 20%;">
                      <h2 class="text-center my-3 pb-3" style="border-bottom: 1px dotted lightgray; font-size: 14px;">Comments</h2>
                    </div>
                  </div> -->
            <div class="table-responsive">
                <table class="table">
                    <thead style="text-align: left;">
                      <tr>
                        <th colspan="1" style="width: 4%;" valign="middle">
                          <h2 style="font-size: 17px;">S.No.</h2>
                        </th>
                        <th colspan="2" valign="middle">
                          <h2 style="font-size: 17px !important;">Parameter</h2>
                        </th>
                        <th colspan="1" style="width: 13%;" valign="middle">
                          <h2 style="font-size: 17px;">Depth of articulation realized (Rate out of 10)</h2>
                        </th>
                        <th colspan="1" style="width: 13%;" valign="middle">
                          <h2 style="font-size: 17px;">Actual awareness, implementation, and impact (Rate out of 10)</h2>
                        </th>
                        <th colspan="1" style="width: 20%;" valign="middle">
                          <h2 style="font-size: 17px;">Comments</h2>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let control of matchingLabelsAndValues; let i = index">
                        <tr>
                          <td>{{ i + 1 }}</td>
                          <td *ngIf="!control.col" colspan="2" style="font-weight: bold; font-size: 17px;">
                            <label for="" class="label" style="font-size: 15px !important;">
                              {{ control.label.slice(control.label.indexOf(' ') + 1) }}
                            </label>
                  
                            <div class="value" style="display: block;" *ngIf="!control?.columns || !control?.rows">
                              <span style="font-style: italic;">Response: </span>
                              <br>
                              <span>{{ control.value != '' ? control.value : '-' }}</span>
                            </div>
                  
                            <div class="value" style="display: block;" *ngIf="control?.rows">
                              <!-- Display labels from columns as th -->
                              <!-- <ng-container *ngFor="let column of control.columns">
                                <label for="" class="label" style="font-size: 15px !important;" *ngIf="column.label !== ''">
                                    {{ column.label }}
                                  </label>
                              </ng-container> -->
                  
                              <!-- Display values as td -->
                              
                              <ng-container *ngFor="let row of control.rows">
                                <ng-container *ngFor="let cell of row">
                                  <ng-container *ngIf="cell.label !== '' || (cell.value !== undefined && cell.value !== null)">
                                    <label for="" class="label" style="font-size: 15px !important; font-weight: 600" *ngIf="cell.label !== ''">
                                      Q: {{ cell?.label }}
                                    </label>
                                    <ng-container *ngIf="cell.value !== undefined && cell.value !== null">
                                      <span style="font-style: italic;">Response: </span>
                                      <br>
                                      <span>{{ cell?.value != '' ? cell?.value : '-' }}</span>
                                      <br>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              
                                  
                                  
                              
                            </div>
                          </td>
                          <td>
                            <!-- Add other columns as needed -->
                          </td>
                          <td>
                            <!-- Add other columns as needed -->
                          </td>
                          <td>
                            <!-- Add other columns as needed -->
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  
            </div>

           
            <div class="row m-0">
                <div class="col-12">
                    <label for="" class="fw-bold" style="font-size: 1rem;margin-bottom: 10px;">Visiting team comments :
                        Why do you think this business should be awarded ? </label>
                    <textarea style="width: 100%;" rows="10"></textarea>
                </div>
            </div>
            <!-- </ng-container> -->
        </div>
    </div>
</ng-container>
</ng-container>

<!-- for jury evaluation start -->
<ng-container *ngIf="juryEvaluation">
    <!-- {{sections | json}} -->
    <div class="add-listings-box" *ngIf="juryLabelValues.length > 0">
        <div class="heading">
            <img src="assets/img/ucci-awards-logo.png" alt="">
            <h2 style="margin-bottom: 0;font-size: 25px;">Udaipur Chamber of Commerce & Industry</h2>
            <h2 style="margin-top: 0;font-size: 25px;">UCCI Excellence Awards 2025</h2>
            <h2 style="margin-bottom: 0;font-size: 25px;">Jury Dossier- {{award_name}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Company Name- {{company_name}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Contact Person- {{contactPersonName}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Contact Number- {{companyNumber}}</h2>
            <h2 style="margin-top: 0;font-size: 25px;">Company Address- {{companyAddress}}</h2>
            <br>
            <div *ngIf="award_name !== 'Vedanta Hindustan Zinc CSR Award'" style="width: 100%; padding-left: 50px; padding-right: 31px; display: flex; justify-content: center; text-align: justify; ">{{companySummary}}</div>
            <!-- <h2>{{title}} {{nextYear}} / {{hindiTitle}} {{nextYear}}</h2> -->
            <!-- <p> Please complete the form below and provide details of your business and company.
                कृपया निम्नलिखित फार्म को पूरा भरें तथा अपने व्यवसाय एवं कम्पनी के विषय में जानकारी देवे:</p> -->
        </div>

        <div class="d-flex justify-content-between align-items-center position-sticky button_div"
            style="top: 0;background:white;padding:20px 0;">
            <p-button type="button" #back_btn class="test " (click)="backBtn()" icon="bx bx-arrow-back"
                label="" iconPos="left">
            </p-button>
            <button (click)="getPDF()" class="pdf_btn">Save as PDF</button>
        </div>
        <!-- <br> -->

      

        <div class="preview">
          
            <div class="table-responsive">
                <table class="table">
                    <thead style="text-align: left;">
                      <tr>
                        <!-- <th colspan="1" style="width: 4%;" valign="middle">
                          <h2 style="font-size: 17px;">S.No.</h2>
                        </th> -->
                        <th colspan="2" valign="middle">
                          <h2 style="font-size: 17px !important;">Questions & Responses</h2>
                        </th>
                       
                        <!-- <th colspan="1" style="width: 30%;" valign="middle">
                          <h2 style="font-size: 17px;">Remarks</h2>
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let control of juryLabelValues; let i = index">
                        <tr>
                          <!-- <td>{{ i + 1 }}</td> -->
                          <td *ngIf="!control.col" colspan="2" style="font-weight: bold; font-size: 17px;">
                            <label for="" class="label" style="font-size: 15px !important;">
                              {{ control.label.slice(control.label.indexOf(' ') + 1) }}
                            </label>
                  
                            <div class="value" style="display: block;" *ngIf="control?.value">
                              <span style="font-style: italic;">Response: </span>
                              <br>
                              <span>{{ control.value != '' ? control.value : '-' }}</span>
                            </div>
                  <ng-container *ngIf="!control?.value">
                            <div class="value" style="display: block;" *ngIf="control?.rows">
                              <!-- Display labels from columns as th -->
                              <!-- <ng-container *ngFor="let column of control.columns">
                                <label for="" class="label" style="font-size: 15px !important;" *ngIf="column.label !== ''">
                                    {{ column.label }}
                                  </label>
                              </ng-container> -->
                  
                              <!-- Display values as td -->
                              
                              
                              <ng-container>
                              <tr>
                                <ng-container *ngFor="let column of control.columns">
                                  <th>{{ column.label }}</th>
                                </ng-container>
                              </tr>
                              <ng-container *ngFor="let row of control.rows">
                                <tr>
                                  <ng-container *ngFor="let cell of row">
                                    <ng-container>
                                        <td *ngIf="cell.label !== '' || cell.value !== undefined && cell.value.trim() !== ''">
                                            <ng-container *ngIf="cell.label !== ''; else showValue">
                                              <label for="" class="label" style="font-weight: 600; display: flex !important;">
                                                {{ cell?.label }}
                                              </label>
                                            </ng-container>
                                            <ng-template #showValue>
                                              <span>{{ cell?.value }}</span>
                                            </ng-template>
                                          </td>
                                          
                                    </ng-container>
                                  </ng-container>
                                </tr>
                              </ng-container>
                            </ng-container> 
                             <!-- <ng-container  *ngIf="control.columns[1].label !== 'Designation / पद'">
                                <ng-container *ngFor="let row of control.rows" >
                                    <ng-container *ngFor="let cell of row">
                                      <ng-container *ngIf="cell.label !== '' || (cell.value !== undefined && cell.value !== null)">
                                        <label for="" class="label" style="font-size: 15px !important; font-weight: 600" *ngIf="cell.label !== ''">
                                          Q: {{ cell?.label }}
                                        </label>
                                        <ng-container *ngIf="cell.value !== undefined && cell.value !== null">
                                          <span style="font-style: italic;">Response: </span>
                                          <br>
                                          <span>{{ cell?.value != '' ? cell?.value : '-' }}</span>
                                          <br>
                                        </ng-container>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                             </ng-container> -->
                              
                            </div>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                
                  

            </div>

           
            <div class="row m-0">
                <div class="col-12">
                    <label for="" class="fw-bold" style="font-size: 1rem;margin-bottom: 10px;">Visiting team comments :
                        Why do you think this business should be awarded ? </label>
                    <textarea style="width: 100%;" rows="10"></textarea>
                </div>
            </div>
            <!-- <ng-container *ngFor="let item of filteredControls">
                <iframe [src]="getSafeUrl(item.value)" width="100%" height="600px" style="display: block; margin: auto; border: 1px solid #ccc;"></iframe>
              </ng-container> -->
              
              
            <!-- </ng-container> -->
        </div>
    </div>
</ng-container>
<!-- for jury evaluation end -->